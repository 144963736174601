import React from "react";

import Service from "./Service";
import ManejoTerapias from "./ManejoTerapias";

export default function Services() {

	return (
		<div id="servicios" className="services-block">
			<h2
				style={{
					alignSelf: "center",
					textAlign: "center",
					padding: "30px 10px 10px 10px",
				}}>
				Servicios Integrales para la Comunidad
			</h2>
			<h3
				style={{
					alignSelf: "center",
					textAlign: "center",
					padding: "0px 10px 10px 10px",
				}}>
				¡Somos Tu Farmacia!</h3>
			<Service
				id={"entrega-al-hogar"}
				invert={false}
				url="/assets/new/entrega.png"
				title="Nuestro servicio de entregas al hogar"
				body="Nuestro servicio de entrega a domicilio incluye medicamentos y también artículos de primera necesidad de nuestro Mini-Market. Sólo haz tu orden por teléfono o por correo electrónico y te la llevamos a la comodidad de tu hogar. Así de fácil."
			/>
			<Service
				id={"compounding"}
				invert={true}
				url="/assets/new/compounding.png"
				title="Servicio Especializado de Compounding: Medicamentos a Tu Medida">
				<p>
					Somos una de las pocas farmacias en Puerto Rico especializadas en compounding, un servicio diseñado para preparar medicamentos personalizados para aquellos con necesidades específicas que no pueden ser cubiertas por los productos disponibles en el mercado. Como parte de la red de farmacias de One Care Compounding, nuestro laboratorio cuenta con equipo especializado de la más alta calidad para elaborar medicamentos hechos a la medida, garantizando que recibas exactamente lo que necesitas de manera rápida y efectiva.

				</p>
			</Service>
			<Service
				id={"recetario-automatizado"}
				invert={false}
				url="/assets/new/automatic.png"
				title="Farmacia con Amplio Recetario Automatizado">
				<h5>Robot Dispensador</h5>
				<p>
					Nuestro sistema automático de alta tecnología para la dispensación de medicamentos agiliza el proceso y minimiza la posibilidad de errores. Esto significa que puedes confiar en una dispensación rápida y precisa, garantizando tu seguridad y bienestar.
				</p>
				<h5>Empaque "Blister"</h5>
				<p>
					Utilizamos tecnología avanzada de empaque "blister" para asegurar que tus medicamentos se entreguen de manera protegida y organizada. Este sistema es ideal para personas que toman múltiples medicamentos, personas de edad avanzada y/o cuidadores, ya que facilita la administración correcta de las dosis.
				</p>

			</Service>
			<Service
				id={"cuidado-farmaceutico"}
				invert={true}
				url="/assets/new/fe-care.png"
				title="Cuidado Farmacéutico">
				<p>
					En Farmacia Esperanza, nos enfocamos en ofrecer un cuidado farmacéutico que va más allá de la simple dispensación de medicamentos. Nuestra farmacéutica regente, junto con un dedicado equipo de técnicos de farmacia, brinda orientación personalizada a cada paciente, adaptándose a sus necesidades específicas para asegurar un uso seguro y eficaz de sus medicamentos.
				</p>
				<p>
					Además, extendemos nuestro apoyo a hogares de envejecientes y hospicios, proporcionando servicios especializados que incluyen la gestión de tratamientos y la educación contínua sobre el manejo de medicamentos. Nuestro compromiso es garantizar que todos nuestros pacientes reciban la mejor atención farmacéutica posible.
				</p>
			</Service>
			<Service
				id={"diabetes"}
				invert={false}
				url="/assets/new/diabetes.png"
				title="Educación Especializada en Diabetes">
				<p>
					En Farmacia Esperanza, entendemos la importancia de una gestión adecuada de la diabetes. Nuestra farmacéutica regente, Mabel Febres, cuenta con certificaciones especializadas en educación sobre diabetes otorgadas por la American Association of Clinical Endocrinologists (AACE) y la American Pharmacists Association (APhA).
				</p>
				<p>
					Ofrecemos orientación experta y personalizada, ayudando a los pacientes a comprender mejor su condición, gestionar su tratamiento y adoptar hábitos de vida saludables. Nos comprometemos a proporcionar información clara y práctica para apoyar a nuestros pacientes en el manejo efectivo de la diabetes y mejorar su calidad de vida.
				</p>
			</Service>
			<Service id="vacunacion" invert={true}
				url="/assets/new/vaccines.png"
				title="Servicio de Vacunación">
				<p>
					En Farmacia Esperanza ofrecemos vacunación para adultos y niños. Disponemos de una amplia gama de vacunas, incluyendo influenza, pneumonía, culebrilla, tétano, Virus del Papiloma Humano (VPH), COVID-19, Hepatitis, Virus Respiratorio Sincitial (RSV) entre otras. Aceptamos la mayoría de los planes médicos y, en general, no se requiere receta para la mayoría de las vacunas. Puede coordinar su visita o simplemente pasar por la farmacia. ¡Es rápido y fácil!
				</p>
			</Service>
			<Service id="auto-servicio" invert={false}
				url="/assets/new/fe-auto-servicio.png"
				title="Auto-Farmacia">
				<p>
					En Farmacia Esperanza, queremos hacerte la vida más fácil con nuestro servicio de Auto-Farmacia. Este servicio está diseñado para ofrecerte comodidad y conveniencia en tu farmacia de confianza, permitiéndote realizar compras de medicamentos de forma rápida y libre.
				</p>
				<p>¡Visítanos y experimenta la comodidad de la Auto-Farmacia en Farmacia Esperanza!</p>
			</Service>
			<Service id="mtm" invert={true}
				url="/assets/new/fe-post-servicios.png"
				title="Programa de Manejo de Terapias de Medicamentos (MTM)">
            <div>
              <div>
                <p>
                  Nuestro programa de Manejo de Terapias de Medicamentos está diseñado para optimizar la efectividad de tus tratamientos. Este servicio es especialmente beneficioso para pacientes con condiciones crónicas o que requieren medicamentos de alto costo, e incluye:
                </p>
              </div>
              <ul>
                <li>
                  <strong>Recordatorios de Repeticiones:</strong> Llamadas para recordarte cuándo es el momento de renovar tus medicamentos.
                </li>
                <li>
                  <strong>Revisión Completa de Medicamentos:</strong> Evaluamos todos tus medicamentos, incluyendo los recetados por tu médico y los de venta libre, como vitaminas y suplementos herbarios.
                </li>
                <li>
                  <strong>Lista Personalizada de Medicamentos:</strong> Te proporcionamos una lista detallada que explica cómo tomar tus medicamentos correctamente y te ayuda a seguir tu tratamiento.
                </li>
                <li>
                  <strong>Evaluación de Interacciones y Efectos Secundarios:</strong> Analizamos los posibles riesgos de interacciones entre medicamentos y revisamos que no estés experimentando efectos secundarios.
                </li>
                <li>
                  <strong>Recomendación de Alternativas:</strong> Sugerimos opciones de medicamentos más costo-efectivas cuando sea posible.
                </li>
                <li>
                  <strong>Coordinación con Proveedores de Salud:</strong> Nos contactamos con tus proveedores de salud para resolver cualquier problema relacionado con tus medicamentos.
                </li>
              </ul>
            </div>
			</Service>
		</div>
	);
}
