import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// For carousel
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import AppBar from "./components/AppBar";
import Home from "./pages/Home";
import SendRx from "./pages/SendRx";
import OurPeople from "./pages/OurPeople";
import Services from "./components/Services";
import Compounding from "./components/Compounding";
import ManejoTerapias from "./components/ManejoTerapias";
import FarmaciaPam from "./components/FarmaciaPam";
import ContactUs from "./components/ContactUs";

function App() {
  return (
    <Router>
      <AppBar></AppBar>
      <div className="main-block">
        <Switch>
          <Route path="/servicios">
            <Services />
            <ManejoTerapias />
            {/* <FarmaciaPam /> */}
          </Route>
          <Route path="/compounding">
            <Compounding />
          </Route>
          <Route path="/quienes-somos">
            <OurPeople />
          </Route>
          <Route path="/rx">
            <SendRx />
          </Route>
          <Route path="/contactanos">
            <div className="page-block home-block" style={{ minHeight: "50vh" }}>
              <ContactUs />
            </div>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
