import React from "react";
import Img from "react-image";
import "./Compounding.css";

export default function Compounding() {
  return (
    <div id="compounding" className={"esperanza-block"}>
      <div className={"compounding-block"}>
        <div className="text">
          <h2>Servicio Especializado de Compounding: Medicamentos a Tu Medida</h2>
          <div>
            Somos una de las pocas farmacias en Puerto Rico especializadas en compounding, un servicio diseñado para preparar medicamentos personalizados para aquellos con necesidades específicas que no pueden ser cubiertas por los productos disponibles en el mercado. Como parte de la red de farmacias de One Care Compounding, nuestro laboratorio cuenta con equipo especializado de la más alta calidad para elaborar medicamentos hechos a la medida, garantizando que recibas exactamente lo que necesitas de manera rápida y efectiva.
          </div>
        </div>
        <div style={{ display: 'flex', alignSelf: 'center' }}>
          <p style={{ margin: '0 10px', alignSelf: 'center' }}>
            Visita nuestro portal de farmacias One Care:
          </p>
          <a href="https://onecarecompoundingpr.com">
            <Img
              className="img"
              style={{ 'borderRadius': '10px' }}
              width="100px"
              src={"/assets/one-care.png"}
            />
          </a>
        </div>
        <div className="images">
          <Img
            className="img"
            width="100%"
            src={"/assets/new/fe-mabel-compounding.jpg"}
          />
          <Img
            className="img"
            width="100%"
            src={"/assets/new/fe-compounding-2.jpg"}
          />
        </div>
      </div>
    </div>
  );
}
