import React from "react";
import { HashLink } from "react-router-hash-link";
import Img from "react-image";
import { IconButton, Button, Menu as MaterialMenu } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.menuButtonClick = this.menuButtonClick.bind(this);
    this.menuLinkClick = this.menuLinkClick.bind(this);
  }

  menuButtonClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  menuLinkClick() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <div className="menu-container">
        <div className="menu-icon">
          <Img className="logo" height="100%" src="./assets/logo.png"></Img>
        </div>
        <Navigation open={this.state.isOpen} menuClick={this.menuLinkClick} />
        <MenuButton onClick={this.menuButtonClick} />
      </div>
    );
  }
}

function Navigation(props) {
  const navOpen = props.open ? "btn-nav-open" : "btn-nav-close";
  return (
    <div className={"menu-navigation " + navOpen}>
      <MItem linkClick={props.menuClick} href="/" label="Inicio" />
      {/* <ServiceMenu menuClick={props.menuClick} /> */}
      <MItem linkClick={props.menuClick} href="/#servicios" label="Servicios" />
      <MItem linkClick={props.menuClick} href="/compounding" label="Compounding" />
      <MItem
        linkClick={props.menuClick}
        href="/quienes-somos"
        label="Quienes Somos"
      />
      <MItem linkClick={props.menuClick} href="/rx" label="Envía tu Receta" />
      <MItem
        linkClick={props.menuClick}
        href="/#contactanos"
        label="Contáctanos"
      />
    </div>
  );
}

function MItem(props) {
  return (
    <div className="menu-item">
      <HashLink
        smooth
        href={props.href}
        to={props.href}
        onClick={props.linkClick}
        className="menu-link"
      >
        {props.label}
      </HashLink>
    </div>
  );
}

function MenuButton(props) {
  let active = false;

  function onButtonClick(e) {
    e.preventDefault();
    active = !active;
    console.log("MenuClicked");
    props.onClick();
  }

  return (
    <div className="menu-nav-button">
      <IconButton onClick={onButtonClick}>
        <MenuIcon></MenuIcon>
      </IconButton>
    </div>
  );
}

function ServiceMenu(props) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    props.menuClick();
  };

  return (
    <div className={"menu-item"}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <span className="menu-item-text">Servicios</span>
      </Button>
      <MaterialMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
      >
        <MItem
          linkClick={handleClose}
          href="/#entrega-al-hogar"
          label="Entrega al Hogar"
        />
        <MItem
          linkClick={handleClose}
          href="/#recetario-automatizado"
          label="Recetario Automatizado"
        />
        <MItem
          linkClick={handleClose}
          href="/#cuidado-farmaceutico"
          label="Cuidado Farmacéutico"
        />
        <MItem
          linkClick={handleClose}
          href="/#compounding"
          label="Compounding"
        />
        <MItem
          linkClick={handleClose}
          href="/#mtm"
          label="Manejo de Terapias de Medicamentos (MTM)"
        />
        <MItem
          linkClick={handleClose}
          href="/#pam"
          label="Programa de Adherencia de Medicamentos (PAM)"
        />
        <MItem linkClick={handleClose} href="/#vacunacion" label="Vacunación" />
      </MaterialMenu>
    </div>
  );
}
