import React from "react";

const styles = {
  minHeight: "500px",
};

export default function ManejoTerapias() {
  return (
    <div id="mtm" className={"esperanza-block"} style={styles}>
      <div className={"compounding-block"}>
        <div className="text">
          <h2 className="title">
            <span>Programa de manejo de Terapias de Medicamentos (MTM)</span>
          </h2>
          <div>
            <div>
              <div>
                <p>
                  Nuestro programa de Manejo de Terapias de Medicamentos está diseñado para optimizar la efectividad de tus tratamientos. Este servicio es especialmente beneficioso para pacientes con condiciones crónicas o que requieren medicamentos de alto costo, e incluye:
                </p>
              </div>
              <div>&nbsp;</div>
              <ul>
                <li>
                  <strong>Recordatorios de Reposición:</strong> Llamadas para recordarte cuándo es el momento de renovar tus medicamentos.
                </li>
                <li>
                  <strong>Revisión Completa de Medicamentos:</strong> Evaluamos todos tus medicamentos, incluyendo los recetados por tu médico y los de venta libre, como vitaminas y suplementos herbarios.
                </li>
                <li>
                  <strong>Lista Personalizada de Medicamentos:</strong> Te proporcionamos una lista detallada que explica cómo tomar tus medicamentos correctamente y te ayuda a seguir tu tratamiento.
                </li>
                <li>
                  <strong>Evaluación de Interacciones y Efectos Secundarios:</strong> Analizamos los posibles riesgos de interacciones entre medicamentos y revisamos que no estés experimentando efectos secundarios.
                </li>
                <li>
                  <strong>Recomendación de Alternativas:</strong> Sugerimos opciones de medicamentos más costo-efectivas cuando sea posible.
                </li>
                <li>
                  <strong>Coordinación con Proveedores de Salud:</strong> Nos contactamos con tus proveedores de salud para resolver cualquier problema relacionado con tus medicamentos.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
