import React from "react";

import Introduction from "../components/Introduction";
import Services from "../components/Services";
import ContactUs from "../components/ContactUs";
import Compounding from "../components/Compounding";
import ManejoTerapias from "../components/ManejoTerapias";
import FarmaciaPam from "../components/FarmaciaPam";

export default function Home() {
  return (
    <div className="page-block home-block">
      <Introduction />
      <Services />
      {/* <Services />
      <Compounding />
      <ManejoTerapias />
      <FarmaciaPam /> */}
      <ContactUs />
    </div>
  );
}
