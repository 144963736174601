import React from "react";
import "./FarmaciaPam.css";

export default function FarmaciaPam() {
  return (
    <div id="pam" className={"esperanza-block"}>
      <div className={"pam-block compounding-block"}>
        <div className="text">
          <h2>
            ¡Somos una Farmacia PAM de 5 Estrellas! (Programa de Adherencia de Medicamentos)
          </h2>
          <div>
            <p>
              En Farmacia Esperanza, contamos con el Programa de Adherencia de Medicamentos (PAM), diseñado para garantizar que sigas de manera efectiva las terapias prescritas por tu médico y mantener un buen control de tu condición. Este programa es especialmente beneficioso para pacientes con condiciones crónicas como diabetes, hipertensión y colesterol alto.
            </p>
            <p>Nuestro programa incluye:</p>
          </div>
          <ul>
            <li><strong>Recordatorios de Medicamentos:</strong> Realizamos llamadas para recordarte cuándo tomar tus medicamentos.</li>
            <li>
              <strong>Sincronización de Terapias:</strong> Coordinamos la entrega de tus medicamentos para facilitar su cumplimiento.
            </li>
            <li>
              <strong>Entrega a Domicilio:</strong> Ofrecemos servicio de entrega a casa si no puedes venir a la farmacia a recoger tus medicamentos.
            </li>
          </ul>
          <p>¡Estamos aquí para apoyarte en cada paso de tu tratamiento!</p>
        </div>
        <div className="images">
          <img
            style={{
              display: "block",
              alignSelf: "center",
              maxHeight: "500px",
              width: "100%",
              objectFit: "contain",
            }}
            width="100%"
            src={"/assets/new/mabel-pam.jpg"}
          />
        </div>
      </div>
    </div>
  );
}
