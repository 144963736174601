import React from "react";
import Img from "react-image";
import "./OurPeople.css";
import FarmaciaPam from "../components/FarmaciaPam.js";

export default function OurPeople() {
  return (
    <div className="page-block nuestra-gente-block">
      <h1 className="page-title">Quienes Somos</h1>
      <Mabel />
      <FarmaciaPam />
      <div className="divider" style={{ margin: "25px 50px" }}></div>
      <Team />
    </div>
  );
}

function Mabel() {
  return (
    <div className="esperanza-block">
      <div className="esperanza-item">
        <div className="mabel-image">
          <Img
            style={{ width: "100%", height: "100%" }}
            src="/assets/new/fe-mabel-prof.jpg"
          />
        </div>
      </div>
      <div className="esperanza-item mabel-info">
        <div className="mabel-info">
          <h4>Licenciada Mabel Febres, Farmacéutica Regente</h4>
          <div className="divider"></div>
          <div className="mabel-title">RPH, MS, FACA</div>
          <div className="mabel-description">
            <p><br />
              Con 25 años de exitosa trayectoria en compañías farmacéuticas como Ortho McNeill, Pfizer y Lilly del Caribe, Mabel J. Febres Arroyo ha sido la Farmacéutica Regente de Farmacia Esperanza desde 2015. Mabel posee una Maestría en Manufactura de Productos Farmacéuticos y está certificada en “Compounding Pharmacy”, con especialidades en Manejo de Dolor, Dermatología, Reemplazo Hormonal, Anti-Aging y Veterinaria. Además, está certificada como Educadora en Diabetes por la AACE (American Association of Clinical Endocrinologists) y por la APhA (American Pharmacists Association) y es farmacéutico inmunizador.
            </p>
            <p>
              Mabel es Fellow del ACA (American College of Apothecaries), miembro de la IACP (International Academy of Compounding Pharmacists) y de la A4M (American Academy of Anti-Aging Medicine). Comprometida con su educación contínua, Mabel se mantiene en constante aprendizaje y crecimiento para ofrecer lo mejor y mas innovador a sus pacientes.
            </p>
            <p>Nuestra farmacéutica regente se capacita de manera contínua para poder formular medicamentos #AtuMedida, asegurando que nuestros pacientes reciban la mejor atención personalizada en Farmacia Esperanza.
            </p>
            <p>Mabel es miembro del Colegio de Farmacéuticos de Puerto Rico y de la Junta de Directores de la Asociación de Farmacias de Comunidad.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Team() {
  return (
    <div className="block esperanza-block team-info">
      <h3 className="team-subtitle">NUESTRO PERSONAL TÉCNICO DE PRIMER ORDEN</h3>
      <div className="text">
        <p>
          En Farmacia Esperanza, somos más que una farmacia; somos una familia. Nuestro equipo de técnicos de farmacia y empleadas de piso ha sido parte de nuestra historia durante años, y su compromiso con nuestra comunidad es inigualable. Ellos no solo conocen tus necesidades de salud, sino que también comparten contigo las alegrías y desafíos de la vida diaria. La dedicación y el cariño con los que te atienden reflejan el verdadero espíritu de Farmacia Esperanza. Aquí, cada miembro de nuestro equipo es un pilar fundamental, no solo para la farmacia, sino también para la comunidad que servimos con orgullo.
        </p>
        <p>
          Nuestro personal técnico de primer orden está siempre listo para ofrecerte el apoyo que necesitas con el trato personalizado y de excelencia que tú mereces.
        </p>
      </div>
      <div className="team-image">
        <Img
          style={{ margin: '0 0em', width: "100%", height: "100%" }}
          src="/assets/new/fe-mabel-2.jpg"
        />
      </div>
    </div>
  );
}
